import { useEffect } from 'react';

const NotFound = () => {
    const redirectToHome = () => {
        window.location.href = '/';
    };

    useEffect(() => {
        alert(
            'เว็บไซต์นี้ไม่พบหน้าที่คุณกำลังมองหา (404 - ไม่พบหน้า) กรุณาตรวจสอบ URL และลองอีกครั้งหรือติดต่อผู้ดูแลเว็บไซต์เพื่อขอความช่วยเหลือ.'
        );

        redirectToHome();
    }, []);

    return null;
};

export default NotFound;
