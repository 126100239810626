import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import {
  useLocation
} from 'react-router-dom';

import {
  callService,
  getOrg,
  userDetail,
  getApp
} from '../services/Service'
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { Label } from '@mui/icons-material';

const UserForm = ({ mode }) => {
  const location = useLocation();
  const [button_title, setButtonTitle] = useState("")
  const [selected_group, setSelectedGroup] = useState(0)
  const [group_list, setGroupList] = useState([])
  const [org_list, setOrgList] = useState([])
  const [app_list, setAppList] = useState([])
  const [data, setData] = useState('')
  const [isBusy, setBusy] = useState(mode === "add" ? false : true)
  const [select_org, setSelectionOrg] = useState('')
  const [select_app, setSelectionApp] = useState('')

  useEffect(() => {
    createOrgList()
    createAppList()
    if (mode === "edit") {
      createGroupList()
      setButtonTitle("edit user")
      let param = location.pathname.split('/')[3]
      userDetail('users/detail', { user_id: param })
        .then(res => {
          console.log('res', res)
          setData(res.data.data)
          setBusy(false)
        })
    } else {
      setButtonTitle("add user")
    }
  }, []); // Or [] if effect doesn't need props or state

  const createOrgList = () => {
    getOrg()
      .then(res => {
        let list = []
        let data = res.data.data
        data.map((item) => {
          list.push({
            id: item.org_id,
            label: `${item.org_code} : ${item.org_name}`
          })
        })
        setOrgList(list)
      })
  }

  const createAppList = () => {
    getApp()
      .then(res => {
        let list = []
        let data = res.data.data
        data.map((item) => {
          list.push({
            id: item.app_id,
            label: item.app_name
          })
        })
        setAppList(list)
      })
  }

  const createGroupList = () => {
    let params = {
      "org_id": 0,
      "search": '',
      "isActive": 'A'
    }
    callService('organizations/group/get', params)
      .then(res => {
        let group_list = []
        let data = res.data.data
        data.map((item) => {
          group_list.push({
            id: item.group_id,
            name: item.group_name,
            org_id: item.org_id
          })
        })
        setGroupList(group_list)
      })
  }
  const handleBackButton = () => window.location.href = "/user/list";;

  const handleSaveButton = () => {
    if (data.username === "" || null) {
      alert("คุณยังไม่ระบุ Username \n กรุณาเพิ่ม Username")
    } else if (data.password === "" || null) {
      alert("คุณยังไม่ระบุ Password \n กรุณาเพิ่มPassword")
    } else if (data.first_name === "" || null) {
      alert("คุณยังไม่ระบุชื่อ \n กรุณาเพิ่มชื่อ")
    } else if (data.last_name === "" || null) {
      alert("คุณยังไม่ระบุนามสกุล \n กรุณาเพิ่มนามสกุล")
    } else {
      if (mode === "add") {
        let object = {
          "username": data.username,
          "password": data.password,
          "first_name": data.first_name,
          "last_name": data.last_name,
          "app_id": select_app,
          "org_id": select_org
        }
        // console.log(object)
        callService('users/create', object)
          .then(res => {
            alert("เพิ่มผู้ใช้งานสำเร็จ")
            window.location.href = "/user/list";
          })
          .catch(error => {
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              } else if (error.response.status === 403) {
                alert('คุณไม่มีสิทธิสมัครสมาชิกในแอพพลิเคชั่นนี้')
              }
            }
          })
      } else {
        let user_id = location.pathname.split('/')[3]
        // let group_id = selected_group
        // let group_orgID = group_list.find(function (item) { return item.id == group_id })
        // let org_id = group_orgID.org_id
        let object = {
          "user_id": user_id,
          "usename": data.usename,
          "first_name": data.first_name,
          "last_name": data.last_name,
          // "org_id" : select_org,
          // "app_id" : select_app
        }
        callService('users/update', object)
          .then(res => {
            alert("แก้ไขผู้ใช้งานสำเร็จ")
            window.location.href = "/user/list";
          })
          .catch(error => {
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              }
              if (error.response.status === 403) {
                alert('คุณไม่มีสิทธิในแอพพลิเคชั่นนี้')
              }
            }
          })
      }
    }
  }

  if (isBusy) {
    return <div>Loading...</div>;
  } else {
    return (
      <Card style={{ margin: '1.5rem 15%' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} >
              <TextField
                fullWidth
                id="outlined-username"
                label="Username"
                variant="outlined"
                value={data.username}
                onChange={event => setData({ ...data, username: event.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-passsword"
                label="Password"
                variant="outlined"
                type="password"
                value={data.password}
                onChange={event => setData({ ...data, password: event.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-name"
                label="ชื่อ"
                variant="outlined"
                value={data.first_name}
                onChange={event => setData({ ...data, first_name: event.target.value })}

              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-surname"
                label="นามสกุล"
                variant="outlined"
                value={data.last_name}
                onChange={event => setData({ ...data, last_name: event.target.value })}
              />
            </Grid>
            {mode !== 'edit' && (
              <>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="select-app_id-label">องค์กร</InputLabel>
                    <Select
                      labelid="select-app_id-label"
                      id="select-app_id"
                      value={select_org}
                      label="องค์กร"
                      placeholder='องค์กร'
                      fullWidth
                      onChange={event => setSelectionOrg(event.target.value)}
                    >
                      <MenuItem key={0} value={0}><em>กรุณาเลือกองค์กร</em></MenuItem>
                      {org_list.map((item, index) => {
                        return (<MenuItem key={index + 1} value={item.id}>{item.label}</MenuItem>)
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="select-app_id-label">แอพลิเคชั่น</InputLabel>
                    <Select
                      labelid="select-app_id-label"
                      id="select-app_id"
                      value={select_app}
                      label="แอพลิเคชั่น"
                      placeholder='แอพลิเคชั่น'
                      fullWidth
                      onChange={event => setSelectionApp(event.target.value)}
                    >
                      <MenuItem key={0} value={0}><em>กรุณาเลือกแอพลิเคชั่น</em></MenuItem>
                      {app_list.map((item, index) => {
                        return (<MenuItem key={index + 1} value={item.id}>{item.label}</MenuItem>)
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>

        <CardActions>
          <Grid
            container
            spacing={2}
            direction="row"
            // justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs>
              {/* <p style={{marginLeft:10}}>Last Edit: {data.updated_date.split('T')[0]}</p> */}
              <p style={{ marginLeft: 10 }}>Last Edit: {data.updated_date}</p>
            </Grid>
            {mode === "edit" && (
              <Grid item >
                <Button variant="contained" style={{ backgroundColor: 'gray' }}>reset password</Button>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={handleSaveButton}
              >
                {button_title}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={handleBackButton}
              >
                back
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    )
  }
}
export default UserForm;