import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const CustomPasswordField = ({
    margin,
    size,
    name,
    showLabel,
    lable,
    placeholder,
    handleChange,
    error,
    helperText
}) => {

    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextField
            variant="outlined"
            margin={margin}
            size={size}
            required
            fullWidth
            name={name}
            label={showLabel ? lable : ""}
            placeholder={placeholder}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            autoComplete="current-password"
            error={error}
            helperText={helperText}
            InputProps={{
                autoComplete: 'current-password',
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(prev => !prev)}
                            onMouseDown={(event) => event.preventDefault()}
                        >
                            {showPassword ? <Visibility style={{ color: error ? '#d74544' : '' }} /> : <VisibilityOff style={{ color: error ? "#d74544" : "" }} />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default CustomPasswordField;