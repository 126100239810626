import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';

import {
    callService
} from '../../services/Service'

import {
  callOrg
} from '../../services/Org'


export default function OrgList() {
  const [permis_list, setPermisList] = useState([])
  const [selected_permis_id, setSelectedPermisID] = useState(0)
  const [selected_app_id,setSelectedAppID] = useState(0)
  const [alert_header, setAlertHeader] = useState("")
  const [alert_body, setAlertBody] = useState("")
  const [alert_mode, setAlertMode] = useState("")
  const [open_alert, setOpenAlert] = useState(false);

  const handleClose = () => {
    setOpenAlert(false);
    setSelectedPermisID(0)
    setSelectedAppID(0);
  };

  const columns = [
    { 
      field: 'index', 
      headerName: 'ลำดับ', 
      maxWidth: 50,
      flex: 1,
    },
    { 
      field: 'permis_name', 
      headerName: 'ชื่อการอนุญาติ', 
      maxWidth: 150,
      flex: 1,
    },
    { 
      field: 'permis_action', 
      headerName: 'การกระทำ', 
      maxWidth: 150,
      flex: 1,
    },
    { 
      field: 'main_menu_name', 
      headerName: 'เมนูหลัก', 
      minWidth: 350,
      flex: 1,
    },
    { 
      field: 'sub_menu_name', 
      headerName: 'เมนูรอง', 
      minWidth:250,
      flex: 1,
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleEditClick(event, cellValues);
          }} >
          <EditIcon />
        </IconButton>
      }
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleDeleteClick(event, cellValues);
          }} >
          <DeleteIcon />
        </IconButton>
      }
    }
  ];

  const handleEditClick = (event, cellValues) => {
    event.preventDefault();
    window.location.href = "/permis/edit/"+ cellValues.row.app_id +"/" + cellValues.row.id
  }

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault();
    let permis_id = cellValues.row.id
    let permis_name = cellValues.row.permis_name
    let app_id = cellValues.row.app_id
    let header = "ลบข้อมูลหน่วยงาน"
    let body = "คุณต้องการลบข้อมูล "
    body += "หน่วยงาน " + permis_name
    setAlertHeader(header)
    setAlertBody(body)
    setSelectedPermisID(permis_id)
    setSelectedAppID(app_id)
    setAlertMode("delete")
    setOpenAlert(true)
  }

  const createPermisList = () => {
    callService('applications/permis/get',{ app_id : 0 }).then(res => {
      let all_permis = res.data.data
      console.log(all_permis);
      let temp_permis_list = []
      all_permis.map((item, index) => {
        temp_permis_list.push({
          id: item.permis_id,
          app_id : item.app_id,
          index: index + 1,
          permis_name: item.permis_name,
          permis_action: item.permis_action,
          main_menu_name: item.main_menu_name,
          sub_menu_name: item.sub_menu_name,
        })
      })
      setPermisList(temp_permis_list)
    }).catch(error => {
      console.log(error.response);
      if (error.response.status === 401) {
        window.location.href = "/auth/login";
      }
    })
  }

  const handleAddClick = (event) => {
    event.preventDefault();
    window.location.href = "/permis/add";
  }

  const handleAlertButton = () => {
    let obj = {
      app_id : selected_app_id,
      permis_id: selected_permis_id,
    }
    console.log(obj);
    callService("applications/permis/delete", obj).then(res => {
      handleClose()
      setPermisList([])
      createPermisList()
    }).catch(error => {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 401) {
          window.location.href = "/auth/login";
        }
      }
    })
  }

  useEffect(() => {
    createPermisList()
  }, [])

  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout />
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>Permission Management</Typography>
        </Grid>
        <Grid item >
          <Button
            variant="contained"
            onClick={handleAddClick}
          >
            ADD PERMISSION
          </Button>
        </Grid>
        <Grid item xs={12} >
          <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: "#FFFFFF" }}
              rows={permis_list}
              columns={columns}
              rowsPerPageOptions={[]}
            />
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open_alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alert_header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alert_body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertButton} variant="contained" color="success">
            ตกลง
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}